import React from 'react';
import { useTranslation } from 'react-i18next';

const EngageLearningScreen43 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-6-2">{t('learning.engage.259')}</h4>
      <p>{t('learning.engage.260')}</p>
      <br/>
      <div className='d-flex justify-content-center'>
        <table className='table two-columns' style={{ width: '75%' }}>
          <colgroup>
            <col className='w-50'></col>
            <col></col>
          </colgroup>
          <tbody>
            <tr>
              <td>{t('learning.engage.261')}</td>
              <td>{t('learning.engage.262')}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('learning.engage.263')}</td>
              <td>{t('learning.engage.264')}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('learning.engage.265')}</td>
              <td>{t('learning.engage.266')}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('learning.engage.267')}</td>
              <td>{t('learning.engage.268')}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('learning.engage.269')}</td>
              <td>{t('learning.engage.270')}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('learning.engage.271')}</td>
              <td>{t('learning.engage.272')}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('learning.engage.273')}</td>
              <td>{t('learning.engage.274')}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('learning.engage.275')}</td>
              <td>{t('learning.engage.276')}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )

}

export default EngageLearningScreen43;