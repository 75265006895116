import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step22 } from 'assets/learning';

const EngageLearningScreen11 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-2-2">{t('learning.engage.44')}</h4>
      <p>{t('learning.engage.45.1')}<a href="https://drive.google.com/file/d/15qfUrbY_J-PSEYGg0NXcRArgAyqszaBg/view?usp=sharing" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.45.2')}</a>{t('learning.engage.45.3')}</p>
      <div className='text-center'>
        <Image src={step22} width={'75%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen11;