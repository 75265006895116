import React from 'react';
import { useTranslation } from 'react-i18next';

const EngageLearningScreen53 = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <h2 id="references">{t('learning.engage.428')}</h2>
      <ol>
        <li>{t('learning.engage.429.1')}<a href="https://europa.eu/europass/digitalskills/screen/questionnaire/generic" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.429.2')}</a>{t('learning.engage.429.3')}</li>
        <li>{t('learning.engage.430')}</li>
        <li><a href="https://hbr.org/2015/06/quiz-yourself-do-you-lead-with-emotional-intelligence" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.431.1')}</a>{t('learning.engage.431.2')}</li>
        <li>{t('learning.engage.432')}</li>
        <li>{t('learning.engage.433')}</li>
        <li>{t('learning.engage.434')}</li>
        <li>{t('learning.engage.435')}</li>
        <li>{t('learning.engage.436.1')}<a href="https://www.jotform.com/blog/author/johnboitnott/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.436.2')}</a>{t('learning.engage.436.3')}</li>
        <li>{t('learning.engage.437')}</li>
        <li>{t('learning.engage.438')}</li>
        <li>{t('learning.engage.439')}</li>
        <li><a href="https://docs.google.com/spreadsheets/d/1q8MTWgsZTgrTrJ0FbUUMEylLddH3OAC0jzstbevYKM4/edit?usp=share_link" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.440.1')}</a>{t('learning.engage.440.2')}</li>
        <li>{t('learning.engage.441')}</li>
        <li><a href="https://drive.google.com/drive/u/0/folders/17mYPywDNexr-Hbggunhxo-i6CtyhpcHK" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.442.1')}</a>{t('learning.engage.442.2')}</li>
        <li>{t('learning.engage.443')}</li>
        <li>{t('learning.engage.444')}</li>
        <li>{t('learning.engage.445')}</li>
        <li>{t('learning.engage.446')}</li>
        <li>{t('learning.engage.447')}</li>
        <li>{t('learning.engage.448')}</li>
        <li>{t('learning.engage.449')}</li>
        <li>{t('learning.engage.450')}</li>
        <li>{t('learning.engage.451.1')}<a href="https://haiilo.com/blog/organizational-communication-9-steps-to-create-a-successful-strategy/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.451.2')}</a>{t('learning.engage.451.3')}</li>
        <li>{t('learning.engage.452')}</li>
        <li>{t('learning.engage.453')}</li>
        <li>{t('learning.engage.454.1')}<a href="https://www.linkedin.com/in/cierrasavatgyking/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.454.2')}</a>{t('learning.engage.454.3')}</li>
        <li>{t('learning.engage.455.1')}<a href="https://www.luanwise.co.uk/author/luan" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.455.2')}</a>{t('learning.engage.455.3')}</li>
        <li>{t('learning.engage.456')}</li>
        <li>{t('learning.engage.457')}</li>
        <li>{t('learning.engage.458.1')}<a href="https://www.citiesoflearning.net" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.458.2')}</a></li>
        <li>{t('learning.engage.459.1')}<a href="https://levity.ai/author/hanna" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.459.2')}</a>{t('learning.engage.459.3')}</li>
        <li>{t('learning.engage.460')}</li>
        <li>{t('learning.engage.461.1')}<a href="https://www.instagram.com/jordanuevamedia/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.461.2')}</a>{t('learning.engage.461.3')}</li>
        <li>{t('learning.engage.462')}</li>
      </ol>
    </>
  )

}

export default EngageLearningScreen53;