import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step4132, step4133, } from 'assets/learning';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EngageLearningScreen17 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-1-3">{t('learning.engage.69')}</h4>
      <div className='d-flex flex-direction-row'>
        <div className='col-8'>
          <p>{t('learning.engage.70')}</p>
        </div>
        <div className='col-4 text-center'>
        </div>
      </div>
      <div className='d-flex flex-direction-row align-items-center my-4'>
        <div className='col-4 text-center'>
          <Image src={step4132} width={64} style={{ objectFit: 'contain' }} />
          <p className='pt-4'><a href="https://trello.com" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.71.1')}</a>{t('learning.engage.71.2')}</p>
        </div>
        <div className='col-8 text-center d-print-none'>
          <iframe width={'75%'} height={250} src="https://www.youtube.com/embed/xky48zyL9iA?si=TNijTPC_UDzdm2jQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
      <div className='d-flex flex-direction-row align-items-center my-4'>
        <div className='col-4 text-center'>
          <Image src={step4133} width={64} style={{ objectFit: 'contain' }} />
          <p className='pt-4'><a href="https://asana.com" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.72.1')}</a>{t('learning.engage.72.2')}</p>
        </div>
        <div className='col-8 text-center d-print-none'>
          <iframe width={'75%'} height={250} src="https://www.youtube.com/embed/jY0-gsNImlk?si=Jn7bwiexZXHRJBXe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
      <p>{t('learning.engage.73')}</p>
      <ul style={{ listStyle: 'none' }}>
        <li>
          <span>
            <FontAwesomeIcon icon={'check'} size={'lg'} />&nbsp;&nbsp;
            <a href="https://docs.google.com/document/d/1fI7b_4jY4VTuDIq_O9efxZEwWbKdqCutNFd7M4vzVz4/edit#heading=h.ryxtmkyafmxf" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.74')}</a>
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'check'} size={'lg'} />&nbsp;&nbsp;
            <a href="https://docs.google.com/document/d/10Gm2EYICUhJwOhIBmhtAlnATxEMIJpmjmcHfucLLCKU/edit?usp=share_link" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.75')}</a>
          </span>
        </li>
        <li>
          <span>
            <i className='icon-check'></i>
            <FontAwesomeIcon icon={'check'} size={'lg'} />&nbsp;&nbsp;
            <a href="https://docs.google.com/document/d/1z2qblPz3lzVaSFX7ly7fFuWIdQg6jt8dvlL5g6Hrdng/edit" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.76')}</a>
          </span>
        </li>
      </ul>
    </>
  )

}

export default EngageLearningScreen17;