import { step33 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const EngageLearningScreen12 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2 id="step-3">{t('learning.engage.46')}</h2>
      <h4 id="step-3-1">{t('learning.engage.47')}</h4>
      <div className='d-flex flex-direction-row'>
        <div className='col-8'>
          <p>{t('learning.engage.48')}</p>
        </div>
        <div className='col-4 text-center'>
          <Image src={step33} width={'50%'} />
        </div>
      </div>
      <p>{t('learning.engage.49')}</p>
      <p>{t('learning.engage.50.1')}<b><u>{t('learning.engage.50.2')}</u></b>{t('learning.engage.50.3')}</p>
      <ul>
        <li><b><a href="https://hbr.org/2015/06/quiz-yourself-do-you-lead-with-emotional-intelligence" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.51')}</a></b></li>
      </ul>
    </>
  )

}

export default EngageLearningScreen12;