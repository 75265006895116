import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step473, step4736, step474 } from 'assets/learning';

const EngageLearningScreen47 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-7-3">{t('learning.engage.315')}</h4>
      <p><b>{t('learning.engage.316')}</b></p>
      <p>{t('learning.engage.317')}</p>

      <br />
      <p><b>{t('learning.engage.318')}</b></p>
      <ul>
        <li><b>{t('learning.engage.319.1')}</b>: {t('learning.engage.319.2')}</li>
        <li><b>{t('learning.engage.320.1')}</b>: {t('learning.engage.320.2')}</li>
      </ul>
      <br />
      <p><b>{t('learning.engage.321')}</b></p>
      <p>{t('learning.engage.322.1')}<b>{t('learning.engage.322.2')}</b>{t('learning.engage.322.3')}</p>
      <ul style={{ listStyle: 'none' }}>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} />&nbsp;&nbsp;
            <span className='pl-1'>{t('learning.engage.323')}</span>
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} />&nbsp;&nbsp;
            <span className='pl-1'>{t('learning.engage.324')}</span>
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} />&nbsp;&nbsp;
            <span className='pl-1'>{t('learning.engage.325')}</span>
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} />&nbsp;&nbsp;
            <span className='pl-1'>{t('learning.engage.326')}</span>
          </span>
        </li>
      </ul>
      <p>{t('learning.engage.327.1')}<a href="https://medianetic.me/content-and-seo/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.327.2')}</a>{t('learning.engage.327.3')}</p>
      <p>{t('learning.engage.328')}</p>
      <p>
        <span><b>{t('learning.engage.329.1')}</b>: {t('learning.engage.329.2')}</span><br />
        <span><b>{t('learning.engage.330.1')}</b>: {t('learning.engage.330.2')}</span><br />
        <span><b>{t('learning.engage.331.1')}</b>: {t('learning.engage.331.2')}</span>
      </p>

      <div className='page-break'></div>
      <div className='text-center'>
        <Image src={step473} width={'75%'} />
      </div>
      <br />
      <p>{t('learning.engage.332')}</p>
      <p><b>{t('learning.engage.333.1')}</b>: {t('learning.engage.333.2')}</p>
      <p><b>{t('learning.engage.334.1')}</b>: {t('learning.engage.334.2')}</p>

      <br />
      <p><b>{t('learning.engage.335')}</b></p>
      <p>{t('learning.engage.336')}</p>
      <p>{t('learning.engage.337.1')}<a href="https://ads.google.com/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.337.2')}</a>{t('learning.engage.337.3')}<a href="https://www.luanwise.co.uk/keywords-online-success-factor" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.337.4')}</a>{t('learning.engage.337.5')}</p>

      <div className='page-break'></div>
      <p><b>{t('learning.engage.338')}</b></p>
      <p>{t('learning.engage.339')}</p>
      <p>{t('learning.engage.340')}</p>
      <p>{t('learning.engage.341')}</p>
      <div className='text-center'>
        <Image src={step474} width={'75%'} />
      </div>
      <br />

      <div className='page-break'></div>
      <p><b>{t('learning.engage.342')}</b></p>
      <p><b>{t('learning.engage.343.1')}</b>: {t('learning.engage.343.2')}</p>
      <p><b>{t('learning.engage.344.1')}</b>: {t('learning.engage.344.2')}</p>
      <p>{t('learning.engage.345.1')}<a href="https://app.ritetag.com/hashtag-suggestions" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.345.2')}</a>{t('learning.engage.345.3')}<a href="https://hashtagify.me/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.345.4')}</a>{t('learning.engage.345.5')}</p>
      <p>{t('learning.engage.346')}</p>
      <ul style={{ listStyle: 'none' }}>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} />&nbsp;&nbsp;
            <span className='pl-1'>{t('learning.engage.347')}</span>
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} />&nbsp;&nbsp;
            <span className='pl-1'>{t('learning.engage.348')}</span>
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} />&nbsp;&nbsp;
            <span className='pl-1'>{t('learning.engage.349')}</span>
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} />&nbsp;&nbsp;
            <span className='pl-1'>{t('learning.engage.350')}</span>
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} />&nbsp;&nbsp;
            <span className='pl-1'>{t('learning.engage.351')}</span>
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} />&nbsp;&nbsp;
            <span className='pl-1'>{t('learning.engage.352')}</span>
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} />&nbsp;&nbsp;
            <span className='pl-1'>{t('learning.engage.353')}</span>
          </span>
        </li>
      </ul>

      <p><b>{t('learning.engage.354')}</b></p>
      <p>{t('learning.engage.355')}</p>
      <p>{t('learning.engage.356.1')}<a href="https://www.canva.com" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.356.2')}</a>, <a href="https://crello.com/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.356.3')}</a>{t('learning.engage.356.4')}<a href="https://fotoram.io/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.356.5')}</a>, <a href="https://about.easil.com/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.356.6')}</a>, <a href="https://www.designbold.com/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.356.7')}</a>, <a href="https://www.photopea.com/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.356.8')}</a>, <a href="https://desygner.com/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.356.9')}</a>, <a href="https://pixlr.com/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.356.10')}</a>, <a href="https://www.cartoonize.net/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.356.11')}</a>, <a href="https://www.befunky.com/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.356.12')}</a>, <a href="https://www.fotor.com/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.356.13')}</a>, <a href="https://www.adobe.com/express/create" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.356.14')}</a>, <a href="https://www.designer.io/en/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.356.15')}</a>, <a href="https://artboard.studio/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.356.16')}</a>, <a href="https://doka.photo/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.356.17')}</a>, <a href="https://www.designwizard.com/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.356.18')}</a>{t('learning.engage.356.19')}</p>
      <p>{t('learning.engage.357.1')}<a href="https://docs.google.com/document/d/1sY94piPKUckG6m7wQTnzW-lbWrPCou_XNHMC0fUUEOQ/edit" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.357.2')}</a></p>
      <p>{t('learning.engage.358.1')}<a href="https://digitalmarketinginstitute.com/exam-preparation-resources/practice-exam-questions/certified-digital-marketing-professional-v8/cdmp8-03-social_media-practical_exercises-01" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.358.2')}</a>{t('learning.engage.358.3')}</p>
      <p>{t('learning.engage.359.1')}<a href="https://docs.google.com/document/d/1DiI4tafGKhDAXz8m1h4U3Z8VTeeldMdIA_Bs2-7KqjA/edit?usp=share_link" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.359.2')}</a>.</p>
      <div className='text-center'>
        <Image src={step4736} width={'50%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen47;