import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step12 } from 'assets/learning';

const EngageLearningScreen4 = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <h4 id="step-1-2">{t('learning.engage.17')}</h4>
      <p>{t('learning.engage.18')}</p>
      <Image src={step12} width={'100%'} />
    </>
  )

}

export default EngageLearningScreen4;