import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step4222 } from 'assets/learning';

const EngageLearningScreen23 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-2-2">{t('learning.engage.109')}</h4>
      <p>{t('learning.engage.110.1')}<a href="https://www.redcross.org/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.110.2')}</a>. {t('learning.engage.110.3')}</p>
      <p>{t('learning.engage.111.1')}<a href="https://www.omnially.com/blog/what-is-a-virtual-donor-wall" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.111.2')}</a>{t('learning.engage.111.3')}</p>
      <p>{t('learning.engage.112')}</p>
      <p>{t('learning.engage.113')}</p>
      <p>{t('learning.engage.114')}</p>
      <p>{t('learning.engage.115')}</p>

      <div className='page-break'></div>
      <p><b>{t('learning.engage.116')}</b></p>

      <ol>
        <li>
          <b>{t('learning.engage.117')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.118')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.119')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.120')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.121')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.122')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
      </ol>
      <div className='text-center'>
        <Image src={step4222} width={'50%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen23;