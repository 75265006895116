import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step21 } from 'assets/learning';

const EngageLearningScreen10 = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <h2 id="step-2">{t('learning.engage.39')}</h2>
      <h4 id="step-2-1">{t('learning.engage.40')}</h4>
      <div className='d-flex flex-direction-row'>
        <div className='col-8'>
          <p>{t('learning.engage.41')}</p>
          <p>{t('learning.engage.42.1')}<a href="https://europa.eu/europass/digitalskills/screen/questionnaire/generic" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.42.2')}</a>{t('learning.engage.42.3')}</p>
          <p>{t('learning.engage.43.1')}<a href="https://docs.google.com/forms/d/e/1FAIpQLSfbLKEWGqUHSeYXulxQs3QVDQEycScqxf817TlfcRuH9CmouA/viewform?usp=share_link" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.43.2')}</a>{t('learning.engage.43.3')}</p>
        </div>
        <div className='col-4 text-center'>
          <Image src={step21} width={'80%'} />
        </div>
      </div>
    </>
  )

}

export default EngageLearningScreen10;