import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step1, step2, step3, step4, step5 } from 'assets/learning';

const EngageLearningScreen2 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2 id="5-step-process">{t('learning.engage.3')}</h2>
      <br />
      <div className='d-flex flex-direction-row pb-3'>
        <Image src={step1} width={64} height={64} />
        <div className='pl-4'>
          <h4 className='pb-0'>{t('learning.engage.4')}</h4>
          <p><b>{t('learning.engage.5.1')}</b>: {t('learning.engage.5.2')} <b>{t('learning.engage.5.3')}</b> {t('learning.engage.5.4')}</p>
        </div>
      </div>
      <div className='d-flex flex-direction-row pb-3'>
        <Image src={step2} width={64} height={64} />
        <div className='pl-4'>
          <h4 className='pb-0'>{t('learning.engage.6')}</h4>
          <p><b>{t('learning.engage.7.1')}</b>: {t('learning.engage.7.2')}</p>
        </div>
      </div>
      <div className='d-flex flex-direction-row pb-3'>
        <Image src={step3} width={64} height={64} />
        <div className='pl-4'>
          <h4 className='pb-0'>{t('learning.engage.8')}</h4>
          <p><b>{t('learning.engage.9.1')}</b>: {t('learning.engage.9.2')}</p>
        </div>
      </div>
      <div className='d-flex flex-direction-row pb-3'>
        <Image src={step4} width={64} height={64} />
        <div className='pl-4'>
          <h4 className='pb-0'>{t('learning.engage.10')}</h4>
          <p><b>{t('learning.engage.11.1')}</b>: {t('learning.engage.11.2')}</p>
        </div>
      </div>
      <div className='d-flex flex-direction-row pb-3'>
        <Image src={step5} width={64} height={64} />
        <div className='pl-4'>
          <h4 className='pb-0'>{t('learning.engage.12')}</h4>
          <p><b>{t('learning.engage.13.1')}</b>: {t('learning.engage.13.2')}</p>
        </div>
      </div>
    </>
  )

}

export default EngageLearningScreen2;