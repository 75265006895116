import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { connect12, connect13 } from 'assets/learning';

const ConnectLearningScreen16 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h4 id="chapter-4-2">{t('learning.connect.141')}</h4>
      <p>{t('learning.connect.142')}</p>
      <p>{t('learning.connect.143')}</p>
      <p>{t('learning.connect.144.1')}<a href="https://causecommunications.org/communications-effectiveness-quiz/" target='_blank' rel='nooppener noreferrer'>{t('learning.connect.144.2')}</a>!</p>
      <div className='text-center'>
        <Image src={connect12} width={'50%'} />
      </div>
      <br />
      <p>{t('learning.connect.145.1')}<a href="https://smartchart.org/" target='_blank' rel='nooppener noreferrer'>{t('learning.connect.145.2')}</a>{t('learning.connect.145.3')}</p>
      <div className='text-center'>
        <Image src={connect13} height={80} />
      </div>
    </>
  )
}

export default ConnectLearningScreen16;