import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step11 } from 'assets/learning';

const EngageLearningScreen3 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2 id="step-1">{t('learning.engage.14')}</h2>
      <h4 id="step-1-1">{t('learning.engage.15')}</h4>
      <p>{t('learning.engage.16.1')}<b>{t('learning.engage.16.2')}</b>{t('learning.engage.16.3')}</p>
      <div className='text-center'>
        <Image src={step11} width={'50%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen3;