import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step4121, step4122, step4123 } from 'assets/learning';

const EngageLearningScreen16 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-1-2">{t('learning.engage.65')}</h4>
      <p>{t('learning.engage.66')}</p>
      <div className='d-flex flex-direction-row justify-content-center m-5'>
        <Image src={step4121} width={100} className='mx-3' />
        <Image src={step4122} width={100} className='mx-3' />
        <Image src={step4123} width={100} className='mx-3' />
        <Image src={step4122} width={100} className='mx-3' />
        <Image src={step4121} width={100} className='mx-3' />
      </div>
      <p>{t('learning.engage.67')}</p>
      <p>{t('learning.engage.68.1')}<b>{t('learning.engage.68.2')}</b>{t('learning.engage.68.3')}<b>{t('learning.engage.68.4')}</b>.</p>
    </>
  )

}

export default EngageLearningScreen16;