import { step4241 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const EngageLearningScreen25 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-2-4">{t('learning.engage.127')}</h4>
      <p>{t('learning.engage.128')}</p>
      <ol>
        <li><a href="https://www.gofundme.com/c/charity-fundraising" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.129.1')}</a>: {t('learning.engage.129.2')}</li>
        <li><a href="https://www.betternow.org/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.130.1')}</a>: {t('learning.engage.130.2')}</li>
        <li><a href="https://www.leetchi.com/en" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.131.1')}</a>: {t('learning.engage.131.2')}</li>
        <li><a href="https://www.causevox.com/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.132.1')}</a>: {t('learning.engage.132.2')}</li>
        <li><a href="https://donorbox.org/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.133.1')}</a>: {t('learning.engage.133.2')}</li>
        <li><a href="https://www.facebook.com/fundraisers/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.134')}</a></li>
      </ol>
      <div className='text-center'>
        <Image src={step4241} width={'50%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen25;