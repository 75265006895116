import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step424 } from 'assets/learning';

const EngageLearningScreen22 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-2-1">{t('learning.engage.105')}</h4>
      <div className='d-flex flex-direction-row'>
        <div className='col-8'>
          <p>{t('learning.engage.106')}</p>
        </div>
        <div className='col-4 text-center'>
          <Image src={step424} width={'80%'} style={{ objectFit: 'contain' }} />
        </div>
      </div>
      <p>{t('learning.engage.107')}</p>
      <p><b>{t('learning.engage.108.1')}</b>: {t('learning.engage.108.2')}<b>{t('learning.engage.108.3')}</b>{t('learning.engage.108.4')}<b>{t('learning.engage.108.5')}</b>{t('learning.engage.108.6')}<b>{t('learning.engage.108.7')}</b>{t('learning.engage.108.8')}</p>
    </>
  )

}

export default EngageLearningScreen22;