import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { connect24 } from 'assets/learning';

const ConnectLearningScreen8 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 id="chapter-2">{t('learning.connect.54')}</h2>
      <h4 id="chapter-2-1">{t('learning.connect.55')}</h4>
      <p>{t('learning.connect.56')}</p>
      <div className='box'>
        <p><b>{t('learning.connect.57.1')}</b> - {t('learning.connect.57.2')}:</p>
        <ul>
          <li><b>{t('learning.connect.58.1')}</b> - {t('learning.connect.58.2')}</li>
          <li><b>{t('learning.connect.59.1')}</b> – {t('learning.connect.59.2')}</li>
          <li><b>{t('learning.connect.60.1')}</b> – {t('learning.connect.60.2')}</li>
          <li><b>{t('learning.connect.61.1')}</b> – {t('learning.connect.61.2')}</li>
          <li><b>{t('learning.connect.62.1')}</b> – {t('learning.connect.62.2')}</li>
        </ul>
      </div>
      <div className='box'>
        <p><b>{t('learning.connect.63.1')}</b> – {t('learning.connect.63.2')}:</p>
        <ul>
          <li><b>{t('learning.connect.64.1')}</b> – {t('learning.connect.64.2')} <a href="https://www.youtube.com/watch?v=rGdG8HAbreA" target='_blank' rel='nooppener noreferrer'>{t('learning.connect.64.3')}</a>{t('learning.connect.64.4')}</li>
          <li><b>{t('learning.connect.65.1')}</b> – {t('learning.connect.65.2')}</li>
          <li><b>{t('learning.connect.66.1')}</b> – {t('learning.connect.66.2')} <a href="https://www.youtube.com/watch?v=1xs4I349cdc" target='_blank' rel='nooppener noreferrer'>{t('learning.connect.66.3')}</a>{t('learning.connect.66.4')}</li>
          <li><b>{t('learning.connect.67.1')}</b> – {t('learning.connect.67.2')}</li>
          <li><b>{t('learning.connect.68.1')}</b> – {t('learning.connect.68.2')}</li>
        </ul>
      </div>
      <div className='box'>
        <p><b>{t('learning.connect.69.1')}</b> – {t('learning.connect.69.2')}</p>
        <ul>
          <li>{t('learning.connect.70')}</li>
          <li>{t('learning.connect.71')}</li>
          <li>{t('learning.connect.72')}</li>
          <li>{t('learning.connect.73')}</li>
        </ul>
      </div>
      <p>{t('learning.connect.74')}</p>
      <div className='text-center'>
        <Image src={connect24} width={'50%'} />
      </div>
    </>
  )
}

export default ConnectLearningScreen8;