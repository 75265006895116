import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { step454 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const EngageLearningScreen38 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-5-4">{t('learning.engage.190')}</h4>
      <p>{t('learning.engage.191')}</p>
      <ul style={{ listStyle: 'none' }}>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} />&nbsp;&nbsp;
            <b className='pl-1'>{t('learning.engage.192.1')}</b>{t('learning.engage.192.2')}
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} />&nbsp;&nbsp;
            <b className='pl-1'>{t('learning.engage.193.1')}</b>{t('learning.engage.193.2')}
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} />&nbsp;&nbsp;
            <b className='pl-1'>{t('learning.engage.194.1')}</b>{t('learning.engage.194.2')}
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} />&nbsp;&nbsp;
            <b className='pl-1'>{t('learning.engage.195.1')}</b>{t('learning.engage.195.2')}
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} />&nbsp;&nbsp;
            <b className='pl-1'>{t('learning.engage.196.1')}</b>{t('learning.engage.196.2')}
          </span>
        </li>
      </ul>
      <p>{t('learning.engage.197')}</p>
      <div className='text-center'>
        <Image src={step454} width={'50%'} />
      </div> 
    </>
  )

}

export default EngageLearningScreen38;