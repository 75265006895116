import { step416 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const EngageLearningScreen20 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-1-6">{t('learning.engage.97')}</h4>
      <p>{t('learning.engage.98')}</p>
      <div className='fake-input x6'>&nbsp;</div>
      <div className='text-center'>
        <Image src={step416} width={'50%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen20;