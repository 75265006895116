import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Layout } from 'components';
import * as Icons from 'assets/icons';
import { AppUtils, ModelUtils } from 'utils';
import { AppConstants, ServerConstants } from 'constant';
import NewsCard from './news/card';
import CommunityCard from './community/card';
import { useFetch } from 'hooks/fetch';

const {
  PATH_ADMIN_COMMUNITY, PATH_CONNECT, PATH_EMPOWER, PATH_ENGAGE, PATH_NEWS, PATH_COMMUNITY
} = AppConstants;
const {
  API_METHOD_GET, API_URL_NEWS_LATEST, API_URL_ORGANIZATION_JOINED
} = ServerConstants;

const HomeScreen = () => {

  const { t } = useTranslation();

  const newsFetch = useFetch(API_METHOD_GET, API_URL_NEWS_LATEST)
  const organizationsFetch = useFetch(API_METHOD_GET, API_URL_ORGANIZATION_JOINED)

  const [news, setNews] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    if (AppUtils.isUserAdmin()) {
      window.location.href = PATH_ADMIN_COMMUNITY;
    }
  }, []);

  useEffect(() => {
    if (newsFetch.response) {
      setNews(newsFetch.response.data.map(el => ModelUtils.convertArticleModelB2F(el)));
    }
  }, [newsFetch.response]);

  useEffect(() => {
    if (organizationsFetch.response) {
      setOrganizations(organizationsFetch.response.data.map(el => ModelUtils.convertOrganizationB2F(el)));
    }
  }, [organizationsFetch.response]);

  const onclick = useCallback((route) => window.location = route, []);

  return (
    <Layout>
      <div className="welcome-container">
        <Row>
          <Col className="col-7 offset-5">
            <div className="welcome-content">
              <div className="horizontal-line"></div>
              <div>
                <h2>{t('home.title')}</h2>
                <p>{t('home.description')}</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* Functionalities */}
      <Container className="my-5">
        <h3 className="title">{t('functions.subtitle')}</h3>
        <div className="line-top"></div>
        <Row>
          <Col className="col-4">
            <div className="card card-function engage-background">
              <Image src={Icons.engageWhite} />
              <h4>{t('functions.engage.title')}</h4>
              <p>{t('functions.engage.description')}</p>
              <Button onClick={() => onclick(PATH_ENGAGE)} variant="light" className="text-engage">{t('functions.engage.explore')}</Button>
            </div>
          </Col>
          <Col className="col-4">
            <div className="card card-function empower-background">
              <Image src={Icons.empowerWhite} />
              <h4>{t('functions.empower.title')}</h4>
              <p>{t('functions.empower.description')}</p>
              <Button onClick={() => onclick(PATH_EMPOWER)} variant="light" className="text-empower">{t('functions.empower.explore')}</Button>
            </div>
          </Col>
          <Col className="col-4">
            <div className="card card-function connect-background">
              <Image src={Icons.connectWhite} />
              <h4>{t('functions.connect.title')}</h4>
              <p>{t('functions.connect.description')}</p>
              <Button onClick={() => onclick(PATH_CONNECT)} variant="light" className="text-connect">{t('functions.connect.explore')}</Button>
            </div>
          </Col>
        </Row>
        <div className="line-bottom"></div>
        <div className='row'>
          <div className='col-8 offset-2'>
            <p className='text-center'>{t('home.designed')}</p>
          </div>
        </div>
      </Container>
      {/* Benefits */}
      <div className="light-gray-background mt-5 pt-3">
        <Container className="my-5">
          <h3 className="title mb-5">{t('benefits.title')}</h3>
          <Row>
            <Col className="col-4">
              <div className="card card-benefit">
                <div className="card-benefit-circle">
                  <Image src={Icons.engage} />
                </div>
                <h4>{t('benefits.engage.title')}</h4>
                <p>{t('benefits.engage.description')}</p>
              </div>
            </Col>
            <Col className="col-4">
              <div className="card card-benefit">
                <div className="card-benefit-circle">
                  <Image src={Icons.empower} />
                </div>
                <h4>{t('benefits.empower.title')}</h4>
                <p>{t('benefits.empower.description')}</p>
              </div>
            </Col>
            <Col className="col-4">
              <div className="card card-benefit">
                <div className="card-benefit-circle">
                  <Image src={Icons.connect} />
                </div>
                <h4>{t('benefits.connect.title')}</h4>
                <p>{t('benefits.connect.description')}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* News */}
      <Container className="mt-5 mb-5">
        <h3 className="title">{t('news.latest')}</h3>
        <div className="line-top"></div>
        <Row>
          {news.map(el => <NewsCard el={el} t={t} />)}
        </Row>
        <h5 className="footnote">
          <span>{t('news.more')}</span>
          <a href="#/" className='clickable' onClick={() => onclick(PATH_NEWS)}>{t('news.look')}</a>
        </h5>
      </Container>
      {/* Organizations */}
      <Container className="mt-5">
        <h3 className="title">{t('organizations.recently')}</h3>
        <div className="line-top"></div>
        <Row style={{display: 'flex', justifyContent: 'space-between', alignItems: 'stretch' }}>
          {organizations.map(el => <CommunityCard el={el} t={t} />)}
        </Row>
        <h5 className="footnote">
          <span>{t('organizations.more')}</span>
          <a href="#/" onClick={() => onclick(PATH_COMMUNITY)}>{t('organizations.look')}</a>
        </h5>
      </Container>
    </Layout>
  )
}

export default HomeScreen;