import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step51 } from 'assets/learning';

const EngageLearningScreen49 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2 id="step-5">{t('learning.engage.375')}</h2>
      <div className='d-flex flex-direction-row'>
        <div className='col-8'>
          <p><a href="https://magenest.com/en/steps-to-digital-transformation/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.376.1')}</a>{t('learning.engage.376.2')}</p>
          <p>{t('learning.engage.377')}</p>
        </div>
        <div className='col-4 text-center'>
          <Image src={step51} width={'70%'} />
        </div>
      </div>
      <p>{t('learning.engage.378')}</p>
      <p>{t('learning.engage.379')}</p>
      <p>{t('learning.engage.380')}</p>
      <div className='text-center d-print-none'>
        <br />
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/OMJUQdiUik8?si=nJF02Z810g_Jccq4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <br />
      </div>
    </>
  )

}

export default EngageLearningScreen49;