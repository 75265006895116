import { AppConstants } from 'constant';
import i18next from 'i18next';
import moment from 'moment';

const { AUTH_TOKEN, AUTH_TOKEN_TO_DATE, USER_DATA, USER_ROLE_ADMIN, USER_ROLE_ORGANIZATION, USER_ROLE_MEMBER, DEFAULT_LANG } = AppConstants;

export function getUserValidToDate() {
  return localStorage.getItem(AUTH_TOKEN_TO_DATE);
}
export function isUserLogged() {
  return localStorage.getItem(AUTH_TOKEN);
}

export function isUserAdmin() {
  let userData = localStorage.getItem(USER_DATA);
  if (userData) {
    return JSON.parse(userData).role === USER_ROLE_ADMIN;
  }
  return false;
}

export function isUserOrganization() {
  let userData = localStorage.getItem(USER_DATA);
  if (userData) {
    return JSON.parse(userData).role === USER_ROLE_ORGANIZATION;
  }
  return false;
}
export function isUserMember() {
  let userData = localStorage.getItem(USER_DATA);
  if (userData) {
    return JSON.parse(userData).role === USER_ROLE_MEMBER;
  }
  return false;
}

export function getLoggedUser() {
  let userData = localStorage.getItem(USER_DATA);
  if (userData) {
    return JSON.parse(userData);
  }
  return undefined;
}

export function logOut() {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(AUTH_TOKEN_TO_DATE);
  localStorage.removeItem(USER_DATA);
  changeLanguage('en');
}

export function renderWebAddress(address) {
  if (address && address.includes('http')) {
    return address;
  }
  return '//' + address;
}

export function copyToClipboard(text) {
  navigator.clipboard.writeText(text).then(function () { }, function (err) {
    console.error('Async: Could not copy text: ', err);
  });
}

export function isEventDraft(event) {
  if (!event) {
    return false;
  }
  const startDate = new Date(convertDateToLocaleDate(event.startTime)).getTime();
  const now = new Date().getTime();
  return now < startDate;
}

export function isEventEnded(event) {
  if (!event) {
    return false;
  }
  const endDate = new Date(convertDateToLocaleDate(event.endTime)).getTime();
  const now = new Date().getTime();
  return now > endDate;
}

export function isFairApplyAllowed(fair) {
  if (!getLoggedUser() || !isUserOrganization()) {
    return false;
  }
  const applicationDate = new Date(convertDateToLocaleDate(fair.applicationDateTime)).getTime();
  return applicationDate >= new Date().getTime() && fair.placesLeft > 0 && isUserOrganization();
}

export function isFairPreLive(fair) {
  const startDate = new Date(convertDateToLocaleDate(fair.preLiveDateTime)).getTime();
  const endDate = new Date(convertDateToLocaleDate(fair.startDateTime)).getTime();
  const now = new Date().getTime();
  return now >= startDate && now <= endDate;
}

export function isFairLive(fair) {
  const startDate = new Date(convertDateToLocaleDate(fair.startDateTime)).getTime();
  const endDate = startDate + (fair.durationInMinutes * 60 * 1000);
  const now = new Date().getTime();
  return now >= startDate && now <= endDate;
}

export function isFairFinished(fair) {
  const startDate = new Date(convertDateToLocaleDate(fair.startDateTime)).getTime();
  const endDate = startDate + (fair.durationInMinutes * 60 * 1000);
  const now = new Date().getTime();
  return now > endDate;
}

export function isFairDownloadContentAllowed(fair) {
  const preLiveDateTime = new Date(convertDateToLocaleDate(fair.preLiveDateTime)).getTime();
  const applicationDateTime = new Date(convertDateToLocaleDate(fair.applicationDateTime)).getTime();
  const now = new Date().getTime();
  return now > applicationDateTime && now < preLiveDateTime;
}

export function isFairHotspotChangeAllowed(fair) {
  if (isFairPreLive(fair) || isFairLive(fair) || isFairFinished(fair)) {
    return false;
  }
  return isUserOrganization();
}

export function isFairSessionInfoAllowed(fair, session) {
  if (session.isSessionWithMe && !session.isOpen) {
    if (isFairPreLive(fair) || isFairLive(fair)) {
      return true;
    }
  }
  return false;
}

export function isFairSessionScheduleAllowed(fair, session) {
  const pre = session.isOpen && (isFairPreLive(fair) || isFairLive(fair));
  if (!pre) {
    return false;
  }
  const startTime = new Date(convertDateToLocaleDate(session.startTime)).getTime();
  const endTime = new Date(convertDateToLocaleDate(session.endTime)).getTime();
  const now = new Date().getTime();
  return now < startTime && now < endTime;
}

export function isCalendarViewAllowed(member, organization) {
  if (!member || !organization) {
    return false;
  }
  const loggedUser = getLoggedUser();
  if (isUserOrganization()) {
    if (organization.id === loggedUser.id) {
      return member.accountType === USER_ROLE_ORGANIZATION && member.organizationId === loggedUser.id;
    } else {
      return true;
    }
  } else if (isUserMember()) {
    if (organization.id === loggedUser.organizationId) {
      return member.accountType === USER_ROLE_MEMBER && member.id === loggedUser.id;
    } else {
      return true;
    }
  }
  return false;
}

export function isMyCalendar(member, organization) {
  if (!member || !organization) {
    return false;
  }
  const loggedUser = getLoggedUser();
  if (member.accountType === USER_ROLE_ORGANIZATION) {
    return isUserOrganization() && organization.id === loggedUser.id && member.id === loggedUser.id;
  } else if (member.accountType === USER_ROLE_MEMBER) {
    return isUserMember() && member.organizationId === organization.id && member.id === loggedUser.id;
  }
  return false;
}

export function isMemberLoggedUser(member, organization) {
  if (!member || !organization) {
    return false;
  }
  const loggedUser = getLoggedUser();
  if (isUserOrganization() && member.accountType === USER_ROLE_ORGANIZATION) {
    return organization.id === loggedUser.id && member.id === loggedUser.id;
  } else if (isUserMember() && member.accountType === USER_ROLE_MEMBER) {
    return member.organizationId === organization.id && member.id === loggedUser.id;
  }
  return false;
}

export function isSameDay(date1, date2) {
  const moment1 = moment(date1);
  const moment2 = moment(date2);
  return moment1.isSame(moment2, 'day');
}

export function convertDateToLocaleDate(date, pattern) {
  if (pattern) {
    return moment(date).utc(true).local().format(pattern);
  }
  return moment(date).utc(true).local().toLocaleString();
}

export function convertLocalDateToDate(date) {
  if (date) {
    return moment(date).local().toLocaleString();
  }
  return moment().local().toLocaleString();
}
export function changeLanguage() {
  i18next.changeLanguage(getLanguage());
}
export function getLanguage() {
  const loggedUser = getLoggedUser();
  if (!loggedUser) {
    return localStorage.getItem(DEFAULT_LANG) ?? 'en';
  }
  if (isUserAdmin()) {
    return 'en';
  }
  return loggedUser.language ?? localStorage.getItem('lang') ?? 'en';
}