import React from 'react';
import { useTranslation } from 'react-i18next';

const EngageLearningScreen37 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-5-3">{t('learning.engage.184')}</h4>
      <p>{t('learning.engage.185')}</p>
      <p>{t('learning.engage.186')}</p>
      <p>{t('learning.engage.187')}</p>
      <div className='text-center d-print-none'>
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/wLyrTXysBro?si=mBsPyuDdd348M9p2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <br /><br />
      </div>
      <p>{t('learning.engage.188')}</p>
      <ul>
        <li><a href="https://docs.google.com/document/d/1aHe29UdAps0BRvjx9I7GZcf5UVXawcBHOwGX7KwR67w/edit?usp=share_link" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.189')}</a></li>
      </ul>
    </>
  )

}

export default EngageLearningScreen37;