import React from 'react';
import { Button, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step32 } from 'assets/learning';
import { AppConstants } from 'constant';

const { PATH_ENGAGE_LEARNING } = AppConstants;

const EngageLearningScreen14 = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className='text-center'>
        <Image src={step32} width={'70%'} />
      </div>
      <h4 id="step-3-3">{t('learning.engage.54')}</h4>
      <p><a href="https://flippity.net/ma.php?c=Project%20planning%20and%20scheduling,Resource%20allocation%20and%20management,Task%20delegation,Financial%20planning%20and%20analysis,Accounting%20and%20bookkeeping,Tax%20and%20reporting,Strategic%20planning,Operational%20planning,Resource%20allocation%20and%20utilization,Recruitment%20and%20selection,Performance%20management,Training%20and%20development,Internal%20communication,External%20communication,Crisis%20communication,Reputation%20management,Stakeholder%20engagement,Publicity%20and%20promotion,Root%20cause%20analysis%20and%20problem-solving%20techniques,Brainstorming%20and%20ideation%20sessions,Risk%20assessment%20and%20mitigation,Data%20backup%20and%20recovery,Information%20security%20and%20privacy,Compliance%20with%20regulations,HUMMAN%20RECOURSES,PROJECT%20MANAGEMENT,PLANNING,FINANCE,COMMUNICATION,PUBLIC%20RELATIONS,DATA%20STORAGE%20&%20SECURITY,DESICION_MAKING&t=Manipulatives&b=White%20Fridge" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.55.1')}</a>: {t('learning.engage.55.2')}</p>
      <div className='d-flex flex-direction-row text-center justify-content-center d-print-none'>
        <Button size='lg' className='mr-2' onClick={() => window.open('https://flippity.net/ma.php?c=Project%20planning%20and%20scheduling,Resource%20allocation%20and%20management,Task%20delegation,Financial%20planning%20and%20analysis,Accounting%20and%20bookkeeping,Tax%20and%20reporting,Strategic%20planning,Operational%20planning,Resource%20allocation%20and%20utilization,Recruitment%20and%20selection,Performance%20management,Training%20and%20development,Internal%20communication,External%20communication,Crisis%20communication,Reputation%20management,Stakeholder%20engagement,Publicity%20and%20promotion,Root%20cause%20analysis%20and%20problem-solving%20techniques,Brainstorming%20and%20ideation%20sessions,Risk%20assessment%20and%20mitigation,Data%20backup%20and%20recovery,Information%20security%20and%20privacy,Compliance%20with%20regulations,HUMMAN%20RECOURSES,PROJECT%20MANAGEMENT,PLANNING,FINANCE,COMMUNICATION,PUBLIC%20RELATIONS,DATA%20STORAGE%20&%20SECURITY,DESICION_MAKING&t=Manipulatives&b=White%20Fridge')}>{t('learning.engage.56')}</Button>
        <Button size='lg' className='ml-2' onClick={() => window.open(PATH_ENGAGE_LEARNING + '/answers')}>{t('learning.engage.57')}</Button>
      </div>
    </>
  )

}

export default EngageLearningScreen14;