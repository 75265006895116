import React from 'react';
import { useTranslation } from 'react-i18next';

import { connect21 } from 'assets/learning';
import { Image } from 'react-bootstrap';

const ConnectLearningScreen5 = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className='d-flex flex-direction-row'>
        <div className='col-8'>
          <h4 id="chapter-1-3">{t('learning.connect.16')}</h4>
          <p><b>{t('learning.connect.17')}</b></p>
        </div>
        <div className='col-4 text-center'>
          <Image src={connect21} width={'50%'} />
        </div>
      </div>


      <h6 id="chapter-1-3-1">{t('learning.connect.18')}</h6>
      <p>{t('learning.connect.19')}</p>
      <p>{t('learning.connect.20')}</p>
      <ul>
        <li>{t('learning.connect.21')}</li>
        <li>{t('learning.connect.22')} <a href="https://youtu.be/-30m8D6gTrg" target='_blank' rel='nooppener noreferrer'>https://youtu.be/-30m8D6gTrg</a></li>
        <li>{t('learning.connect.23')} <a href="https://youtu.be/Cts4QVuVVbs" target='_blank' rel='nooppener noreferrer'>https://youtu.be/Cts4QVuVVbs</a></li>
        <li>{t('learning.connect.24')}</li>
      </ul>

      <h6 id="chapter-1-3-2">{t('learning.connect.25')}</h6>
      <p>{t('learning.connect.26')}</p>

      <h6 id="chapter-1-3-3">{t('learning.connect.27')}</h6>
      <p>{t('learning.connect.28')}</p>

    </>
  )
}

export default ConnectLearningScreen5;