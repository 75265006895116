import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Empty, FormInput, Layout, Loader } from 'components';
import { calendar, empowerWhite } from 'assets/icons';
import { AppConstants, ServerConstants } from 'constant';
import { AppUtils, ModelUtils } from 'utils';
import { useFetch } from 'hooks/fetch';
import EventItem from './item';

const { PATH_EMPOWER_LOBBY, PATH_EMPOWER_LEARNING, PATH_EMPOWER_POT } = AppConstants;
const { API_METHOD_GET, API_URL_EVENTS } = ServerConstants;

const FunctionEmpowerScreen = () => {

  const { t } = useTranslation();
  const eventsFetch = useFetch(API_METHOD_GET, API_URL_EVENTS);
  const [eventsFiltered, setEventsFiltered] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [reload, setReload] = useState(0);
  const events = useRef([]);

  useEffect(() => {
    if (reload > 0) {
      eventsFetch.forceFetchData();
    }
  }, [reload])

  useEffect(() => {
    if (eventsFetch.response) {
      events.current = eventsFetch.response.data.map(el => ModelUtils.convertEventModelB2F(el));
      setEventsFiltered(events.current);
    }
  }, [eventsFetch.response]);

  useEffect(() => {
    let array = events.current;
    if (searchTerm && searchTerm.trim() !== '') {
      array = array.filter(el => el.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    setEventsFiltered(array);
  }, [searchTerm]);

  return (
    <Layout>
      <div className="function-wallpaper empower">
        <Container style={{ height: '100%', width: '100%' }}>
          <div className="d-flex flex-direction-row justify-content-around align-items-center" style={{ height: '100%' }}>
            <Image src={empowerWhite} className='d-none d-lg-block mr-5' height={'70%'} width={'100%'} />
            <div className='ml-5'>
              <div className="d-flex flex-direction-row align-items-center mb-3">
                <Image src={empowerWhite} />
                <span className="ml-3">{t('functions.empower.title')}</span>
              </div>
              <h2>{t('functions.empower.header.title')}</h2>
              <p>{t('functions.empower.header.description')}</p>
              <Button variant='secondary' className="mt-2" disabled={!AppUtils.isUserLogged()} onClick={() => window.location.href = PATH_EMPOWER_LOBBY}>{t('functions.empower.header.action.virtual')}</Button>
              <Button variant='secondary' className="mt-2 ml-2" onClick={() => window.location.href = PATH_EMPOWER_LEARNING}>{t('learning.goto')}</Button>
              <Button variant='secondary' className="mt-2 ml-2" onClick={() => window.location.href = PATH_EMPOWER_POT}>{t('learning.pot.goto')}</Button>
            </div>
          </div>
        </Container>
      </div>
      {AppUtils.isUserOrganization() ?
        <Container className="mb-5">
          <div className="d-flex flex-direction-row align-items-center justify-content-between mt-5 mb-4">
            <div>
              <div className="d-flex flex-direction-row align-items-center mb-3">
                <Image src={calendar} />
                <h3 className="text-primary mx-3 mb-0">{t('functions.empower.events.title')}</h3>
              </div>
            </div>
            <FormInput id="searchTerm" placeholder={t('functions.empower.events.search')} onChange={(e) => setSearchTerm(e.target.value)} className="mb-0" />
          </div>
          {eventsFetch.loading ? <div className="mt-2"><Loader /></div> :
            events.current.length === 0 ? <Empty messageKey={t('functions.empower.events.empty')} /> :
              <div className="mb-3">
                <Row>
                  {eventsFiltered.map(el => <Col className="col-4"><EventItem el={el} events={events} setReload={() => setReload((old) => old + 1)} t={t} /></Col>)}
                </Row>
              </div>
          }
        </Container>
        : undefined}
    </Layout >
  )
}

export default FunctionEmpowerScreen;