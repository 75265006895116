const hotspotTypes = {
  "Engage": {
    "TEAM_MEETINGS": {
      "intro": "Place to be used for scheduling team meetings.",
      "title": "Team Meetings"
    },
    "TODO_LIST": {
      "intro": "Place for all people involved to share their to-do lists.",
      "title": "To-Do Lists"
    },
    "WHITEBOARD": {
      "intro": "If you wish to share something with the entire team of brainstorm together.",
      "title": "Info Table"
    },
    "PROJECT_MANAGEMENT": {
      "intro": "Have all your project management tools at one place available for the entire team.",
      "title": "Projects"
    },
    "CALENDAR": {
      "intro": "Tracking meetings, events, travels, organizing and following up the tasks, reminders of the events.",
      "title": "Calendar"
    },
    "FILE_STORAGE": {
      "intro": "This will be used for keeping information safe and guaranteeing access to all.",
      "title": "File Storage and Share"
    },
    "FINANCIAL_MANAGEMENT": {
      "intro": "With these apps we can organize our finances, project budget, the profit loss balance, we can predict the costs for any events and help with our taxes.",
      "title": "Finances"
    },
    "SOCIAL_MEDIA": {
      "intro": "This will help us store all the management tools at one place, helping coordinate efforts on social media.",
      "title": "Communication"
    }
  },
  "Empower": {
    "ICE_BREAKERS": {
      "intro": "Make sure that participants get to know each other, break the ice in the room and energize through this hotspot.",
      "title": "Games and Icebreakers"
    },
    "PROJECTING": {
      "intro": "Offer your participants presentations relevant to the topic of your event.",
      "title": "Presentations"
    },
    "MINGLING": {
      "intro": "Use for activities to associate, mix, or interact with others, as at a social event or coffee breaks.",
      "title": "Mingling"
    },
    "BRAINSTORMING": {
      "intro": "Help your  participants unleash their ideas and work together as a team.",
      "title": "Brainstorming"
    },
    "BREAKOUT_ROOMS": {
      "intro": "Share links for your participants to be able to access separate working groups during the meeting.",
      "title": "Breakout Rooms"
    },
    "LIVE_SESSIONS": {
      "intro": "Share the link for the main live session.",
      "title": "Live Session"
    },
    "EVALUATION": {
      "intro": "Share a link to receive the evaluation and feedback of your participants.",
      "title": "Evaluation"
    },
    "FILE_STORAGE": {
      "intro": "Keep the event's information safe and guarantee access to all.",
      "title": "File Storage and Share"
    },
    "AGENDA": {
      "intro": "Share your agenda with the participants of the event.",
      "title": "Agenda"
    }
  },
  "Connect": {
    "THOUGHTS": {
      "intro": "Get in Touch With Us! This is an open space for feedback and contacting the organization. For easily connecting with visitors to your virtual stand, we suggest you to create a WhatsApp group and paste the link leading to it in the “Items” field.",
      "title": "Get in Touch With Us"
    },
    "ABOUT": {
      "intro": "Learn about the organization!",
      "title": "About Us"
    },
    "WORK": {
      "intro": "Our experience that brought us here",
      "title": "Our Work"
    }
  }
}

export default hotspotTypes;