import React from 'react';
import { useTranslation } from 'react-i18next';

const EngageLearningScreen32 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-4-2">{t('learning.engage.172')}</h4>
      <p>{t('learning.engage.173')}</p>
      <div className='text-center d-print-none'>
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/fgsJB3URPss?si=NT_-Ux6KmIs-QB73" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <br /><br />
      </div>
      <p>{t('learning.engage.174')}</p>
      <div className='text-center d-print-none'>
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/_vUPbMqlxfY?si=eIR6mh61dBgBK_Jm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <br /><br />
      </div>
    </>
  )

}

export default EngageLearningScreen32;