import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-bootstrap';
import { step415 } from 'assets/learning';

const EngageLearningScreen19 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-1-5">{t('learning.engage.91')}</h4>
      <p>{t('learning.engage.92')}</p>
      <ul style={{ listStyle: 'none' }}>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} />&nbsp;&nbsp;
            <a href="https://www.google.com/drive/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.93.1')}</a>: {t('learning.engage.93.2')}
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} />&nbsp;&nbsp;
            <a href="https://trello.com/en/login" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.94.1')}</a>: {t('learning.engage.94.2')}
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} />&nbsp;&nbsp;
            <a href="https://slack.com" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.95.1')}</a>: {t('learning.engage.95.2')}
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} />&nbsp;&nbsp;
            <a href="https://wordpress.com" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.96.1')}</a>: {t('learning.engage.96.2')}
          </span>
        </li>
      </ul>
      <div className='text-center'>
        <Image src={step415} width={'50%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen19;