import React, { useEffect, useState } from 'react';
import { Button, Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Empty, Layout, Loader } from 'components';
import { connectColored, connectWhite } from 'assets/icons';
import { useFetch } from 'hooks/fetch';
import { AppConstants, ServerConstants } from 'constant';
import FunctionConnectCardSmall from './card-small';
import { AppUtils, ModelUtils } from 'utils';

const { PATH_CONNECT_LEARNING, PATH_CONNECT_FAIR } = AppConstants;
const { API_METHOD_GET, API_URL_FAIRS_APPLIED, API_URL_FAIRS_UPCOMING } = ServerConstants;

const FunctionConnectScreen = () => {

  const { t } = useTranslation();
  const myFetch = useFetch(API_METHOD_GET, AppUtils.isUserLogged() ? API_URL_FAIRS_APPLIED : undefined);
  const upcomingFetch = useFetch(API_METHOD_GET, API_URL_FAIRS_UPCOMING);
  const [linkModalVisible, setLinkModalVisible] = useState(undefined);
  const [myFairs, setMyFairs] = useState([]);
  const [upcomingFairs, setUpcomingFairs] = useState([]);
  const [joinFair, setJoinFair] = useState(undefined);

  useEffect(() => {
    if (myFetch.response) {
      const data = myFetch.response.data;
      const converted = data.map(el => ModelUtils.convertFairModelB2F(el));
      setJoinFair(converted.find(el => AppUtils.isFairLive(el)));
      setMyFairs(converted);
    }
  }, [myFetch.response]);

  useEffect(() => {
    if (upcomingFetch.response) {
      setUpcomingFairs(upcomingFetch.response.data.map(el => ModelUtils.convertFairModelB2F(el)));
    }
  }, [upcomingFetch.response]);

  return (
    <Layout>
      <div className="function-wallpaper connect">
        <Container style={{ height: '100%', width: '100%' }}>
          <div className="d-flex flex-direction-row justify-content-around align-items-center" style={{ height: '100%' }}>
            <Image src={connectWhite} className='d-none d-lg-block mr-5' height={'70%'} width={'100%'} />
            <div className='ml-5'>
              <div className="d-flex flex-direction-row align-items-center mb-3">
                <Image src={connectWhite} />
                <span className="ml-3">{t('functions.connect.title')}</span>
              </div>
              <h2>{t('functions.connect.header.title')}</h2>
              <p>{t('functions.connect.header.description')}</p>
              <Button variant='secondary' disabled={joinFair === undefined} onClick={() => window.location.href = PATH_CONNECT_FAIR + '/' + joinFair?.id} >{t('functions.connect.header.action.join')}</Button>
              <Button variant='secondary' onClick={() => window.location.href = PATH_CONNECT_LEARNING} className='ml-2'>{t('learning.goto')}</Button>
            </div>
          </div>
        </Container>
      </div>
      <Container className="mb-5">
        {AppUtils.isUserLogged() ?
          <>
            <div className="d-flex flex-direction-row align-items-center my-4">
              <Image src={connectColored} />
              <h3 className="text-connect mx-3 mb-0">{t('functions.connect.fairs.my')}</h3>
            </div>
            <Row>
              {myFetch.loading ? <Loader /> :
                myFairs.length === 0 ? <Empty messageKey={t('fairs.empty')} /> :
                myFairs.map(el => <FunctionConnectCardSmall el={el} my={true} t={t} linkModalVisible={linkModalVisible} setLinkModalVisible={setLinkModalVisible} />)}
            </Row>
          </>
          : undefined}
        <div className="d-flex flex-direction-row align-items-center my-4">
          <Image src={connectColored} />
          <h3 className="text-connect mx-3 mb-0">{t('functions.connect.fairs.upcoming')}</h3>
        </div>
        <Row>
          {upcomingFetch.loading ? <Loader /> :
            upcomingFairs.length === 0 ? <Empty messageKey={t('fairs.empty')} /> :
            upcomingFairs.map(el => <FunctionConnectCardSmall el={el} t={t} linkModalVisible={linkModalVisible} setLinkModalVisible={setLinkModalVisible} alreadyApplied={myFairs.find(f => f.id === el.id)} />)}
        </Row>
      </Container>
    </Layout >
  )
}

export default FunctionConnectScreen;