import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { extra2 } from 'assets/learning';

const EngageLearningScreen52 = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <h4 id="exercise">{t('learning.engage.415')}</h4>
      <div className='d-flex flex-direction-row'>
        <div className='col-4 text-center'>
          <Image src={extra2} width={'50%'} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-8'>
          <p>{t('learning.engage.416.1')}<b>{t('learning.engage.416.2')}</b>{t('learning.engage.416.3')}</p>
          <ol>
            <li><b>{t('learning.engage.417.1')}<a href="https://www.youtube.com/watch?v=VznoKyh6AXs&list=WL" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.417.2')}</a>{t('learning.engage.417.3')}<a href="https://www.youtube.com/watch?v=VznoKyh6AXs&list=WL" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.417.4')}</a></b></li>
            <li><b>{t('learning.engage.418.1')}<a href="https://chat.openai.com/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.418.2')}</a>{t('learning.engage.418.3')}</b></li>
          </ol>
        </div>
      </div>
      <p><b>{t('learning.engage.419')}</b></p>
      <p>{t('learning.engage.420.1')}<b>{t('learning.engage.420.2')}</b>{t('learning.engage.420.3')}<b>{t('learning.engage.420.4')}</b>{t('learning.engage.420.5')}<b>{t('learning.engage.420.6')}</b>{t('learning.engage.420.7')}<b>{t('learning.engage.420.8')}</b>{t('learning.engage.420.9')}<b>{t('learning.engage.420.10')}</b>{t('learning.engage.420.11')}</p>
      <p>{t('learning.engage.421.1')}<a href="https://github.com/f/awesome-chatgpt-prompts" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.421.2')}</a>{t('learning.engage.421.3')}</p>
      <ol start={3}>
        <li><b>{t('learning.engage.422')}</b></li>
        <li><b>{t('learning.engage.423')}</b></li>
        <li>
          <b>{t('learning.engage.424')}</b>
          <ul>
            <li><a href="https://brilliant.org" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.425.1')}</a>{t('learning.engage.425.2')}</li>
            <li><a href="https://www.udemy.com" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.426.1')}</a>{t('learning.engage.426.2')}</li>
          </ul>
        </li>
      </ol>
      <p>{t('learning.engage.427.1')}<a href="https://docs.google.com/document/d/1am2bl43Evk0cvAGlr_WPv9nAHqdXcKLjONc-A0MGH-8/edit?usp=share_link" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.427.2')}</a>){t('learning.engage.427.3')}<a href="https://docs.google.com/document/d/1UQjM8GGcIK27xRDVBzUEXQKVazydTd5R/edit?usp=share_link&ouid=104668314143553082843&rtpof=true&sd=true" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.427.4')}</a>{t('learning.engage.427.5')}</p>

    </>
  )

}

export default EngageLearningScreen52;