export default class Article {
  private __id: number;
  private __featuredImage: string;
  private __title: string;
  private __text: string;
  private __intro: string;
  private __datePublished: string;
  private __type: string;
  private __fairId: number;
  private __status: string;
  private __organizationName: string;
  private __deletable: boolean;
  private __editable: boolean;
  private __statusChangeable: boolean;
  private __submissionDate: string;

  constructor(
    id: number,
    featuredImage: string,
    title: string,
    text: string,
    intro: string,
    datePublished: string,
    type: string,
    fairId: number,
    status: string,
    organizationName: string,
    statusChangeable: boolean,
    editable: boolean,
    deletable: boolean,
    submissionDate: string,
  ) {
    this.__id = id;
    this.__featuredImage = featuredImage;
    this.__title = title;
    this.__text = text;
    this.__intro = intro;
    this.__datePublished = datePublished;
    this.__type = type;
    this.__fairId = fairId;
    this.__status = status;
    this.__organizationName = organizationName;
    this.__statusChangeable = statusChangeable;
    this.__editable = editable;
    this.__deletable = deletable;
    this.__submissionDate = submissionDate;
  }

  public get id() { return this.__id; }
  public get featuredImage() { return this.__featuredImage; }
  public get title() { return this.__title; }
  public get text() { return this.__text; }
  public get intro() { return this.__intro; }
  public get datePublished() { return this.__datePublished; }
  public get type() { return this.__type; }
  public get fairId() { return this.__fairId; }
  public get status() { return this.__status; }
  public get editable() { return this.__editable; }
  public get deletable() { return this.__deletable; }
  public get organizationName() { return this.__organizationName; }
  public get submissionDate() { return this.__submissionDate; }

  public toJSON() {
    return {
      id: this.__id,
      featuredImage: this.__featuredImage,
      title: this.__title,
      text: this.__text,
      intro: this.__intro,
      datePublished: this.__datePublished,
      type: this.__type,
      fairId: this.__fairId,
      status: this.__status,
      organizationName: this.__organizationName,
      editable: this.__editable,
      deletable: this.__deletable,
      statusChangeable: this.__statusChangeable,
      submissionDate: this.__submissionDate
    }
  }

}