import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { connect7, connect8 } from 'assets/learning';

const ConnectLearningScreen12 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h4 id="chapter-3-2">{t('learning.connect.96')}</h4>
      <p>{t('learning.connect.97')}</p>
      <ul>
        <li><b>{t('learning.connect.98.1')}</b>: {t('learning.connect.98.2')}</li>
        <li><b>{t('learning.connect.99.1')}</b>: {t('learning.connect.99.2')}</li>
        <li><b>{t('learning.connect.100.1')}</b>: {t('learning.connect.100.2')}</li>
      </ul>
      <div className='d-flex flex-direction-row'>
        <div className='d-flex flex-direction-row justify-content-center align-items-end col-4'>
          <Image src={connect7} height={120} />
          <Image src={connect8} height={120} />
        </div>
        <p className='col-8'>{t('learning.connect.101')}</p>
      </div> 
    </>
  )
}

export default ConnectLearningScreen12;