import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step43, step431 } from 'assets/learning';

const EngageLearningScreen26 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-3">{t('learning.engage.135')}</h4>
      <div className='d-flex flex-direction-row'>
        <div className='col-8'>
          <p>{t('learning.engage.136.1')}<a href="https://www.google.com/sheets/about/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.136.2')}</a>{t('learning.engage.136.3')}</p>
          <p><a href="https://dynamics.microsoft.com/en-us/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.137.1')}</a>{t('learning.engage.137.2')}</p>
        </div>
        <div className='col-4 text-center'>
          <Image src={step43} width={'25%'} style={{ objectFit: 'contain' }} />
        </div>
      </div>
      <p>{t('learning.engage.138')}</p>
      <div className='d-flex flex-direction-row d-print-none'>
        <div className='col-6 px-2'><iframe width={'100%'} height="315" src="https://www.youtube.com/embed/TENAbUa-R-w?si=UV9_UYIkVzQikbl5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
        <div className='col-6 px-2'><iframe width={'100%'} height="315" src="https://www.youtube.com/embed/b6zdxyFsQV?si=7_a8L9dIo-bmMqoL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
      </div>

      <div className='page-break'></div>
      <p><b>{t('learning.engage.139.1')}<a href="https://docs.google.com/document/d/18W-t-xf3d0NRUG1Iv89nMj6TvRFKlbElqco-ZjUPmhw/edit" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.139.2')}</a>{t('learning.engage.139.3')}</b></p>
      <ul>
        <li><a href="#/" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.140')}</a></li>
        <li><a href="https://docs.google.com/spreadsheets/d/1q8MTWgsZTgrTrJ0FbUUMEylLddH3OAC0jzstbevYKM4/edit?usp=share_link" target='_blank' rel='nooppener noreferrer'>{t('learning.engage.141')}</a></li>
      </ul>
      <br/><br/>
      <div className='text-center'>
        <Image src={step431} width={'50%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen26;