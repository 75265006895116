import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step44 } from 'assets/learning';

const EngageLearningScreen30 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-4">{t('learning.engage.159')}</h4>
      <div className='d-flex flex-direction-row'>
        <div className='col-8'>
          <p>{t('learning.engage.160.1')}<b>{t('learning.engage.160.2')}</b>{t('learning.engage.160.3')}<b>{t('learning.engage.160.4')}</b>{t('learning.engage.160.5')}</p>
        </div>
        <div className='col-4 text-center'>
          <Image src={step44} width={'50%'} />
        </div>
      </div>
    </>
  )

}

export default EngageLearningScreen30;