import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step461, step462 } from 'assets/learning';

const EngageLearningScreen41 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-6">{t('learning.engage.210')}</h4>
      <div className='d-flex flex-direction-row'>
        <div className='col-8'>
          <p>{t('learning.engage.211')}</p>
        </div>
        <div className='col-4 text-center'>
          <Image src={step461} width={'50%'} style={{ objectFit: 'contain' }} />
        </div>
      </div>
      <br />
      <ul>
        <li>
          <b>{t('learning.engage.212.1')}</b>: {t('learning.engage.212.2')}
        </li>
        <li>
          <b>{t('learning.engage.213.1')}</b>: {t('learning.engage.213.2')}
        </li>
      </ul>
      <div className='text-center'>
        <Image src={step462} width={'50%'} style={{ objectFit: 'contain' }} />
      </div>
    </>
  )

}

export default EngageLearningScreen41;