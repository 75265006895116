import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { connect20 } from 'assets/learning';

const ConnectLearningScreen16 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h4 id="chapter-6">{t('learning.connect.187')}</h4>
      <div className='d-flex flex-direction-row'>
        <div className='col-3 text-center'>
          <Image src={connect20} width={'50%'} />
        </div>
        <div className='col-9'>
          <p className='text-primary'>{t('learning.connect.188')}</p>
          <p className='text-primary'>{t('learning.connect.189')}</p>
          <p>{t('learning.connect.190')}</p>
        </div>
      </div>
      <p className='text-center'><b><i>{t('learning.connect.191')}</i></b></p>
    </>
  )
}

export default ConnectLearningScreen16;