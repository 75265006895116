import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-bootstrap';

import { AppConstants } from 'constant';
import { connect27 } from 'assets/learning';

const { PATH_CONNECT_LEARNING } = AppConstants;

const ConnectLearningScreen16 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 id="chapter-5">{t('learning.connect.146')}</h2>
      <h4 id="chapter-5-1">{t('learning.connect.147')}</h4>
      <p>{t('learning.connect.148')}</p>
      <p>{t('learning.connect.149')}</p>
      <ul>
        <li>{t('learning.connect.150')}</li>
        <li>{t('learning.connect.151')}</li>
        <li>{t('learning.connect.152')}</li>
        <li>{t('learning.connect.153')}</li>
      </ul>
      <p>{t('learning.connect.154.1')}<a href={PATH_CONNECT_LEARNING + '?page=8'}>{t('learning.connect.154.2')}</a>{t('learning.connect.154.3')}</p>
      <div className='text-center'>
        <Image src={connect27} width={'50%'} />
      </div>
    </>
  )
}

export default ConnectLearningScreen16;