import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { connect10, connect11 } from 'assets/learning';

const ConnectLearningScreen15 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h4 id="chapter-4-2">{t('learning.connect.132')}</h4>
      <div className='d-flex flex-direction-row pb-4'>
        <div className='col-4 text-center'>
          <Image src={connect10} width={'75%'} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-8'>
          <p>{t('learning.connect.133')}</p>
        </div>
      </div>
      <ol>
        <li><b>{t('learning.connect.134.1')}</b>: {t('learning.connect.134.2')}</li>
        <li><b>{t('learning.connect.135.1')}</b>: {t('learning.connect.135.2')}<a href="https://www.lifewire.com/lines-in-typography-1078106" target='_blank' rel='nooppener noreferrer'>{t('learning.connect.135.3')}</a>, <a href="https://paletton.com/" target='_blank' rel='nooppener noreferrer'>{t('learning.connect.135.4')}</a>, <a href="https://www.canva.com/learn/use-shapes-creatively-design/" target='_blank' rel='nooppener noreferrer'>{t('learning.connect.135.5')}</a> {t('learning.connect.135.6')} <a href="https://icons8.com/articles/typography-logo-design/" target='_blank' rel='nooppener noreferrer'>{t('learning.connect.135.7')}</a>{t('learning.connect.135.8')}</li>
        <li><b>{t('learning.connect.136.1')}</b>: {t('learning.connect.136.2')}<a href="https://www.youtube.com/watch?v=1-SvuFIQjK8" target='_blank' rel='nooppener noreferrer'>{t('learning.connect.136.3')}</a>!</li>
        <li><b>{t('learning.connect.137.1')}</b>: {t('learning.connect.137.2')}<a href="https://www.youtube.com/watch?v=LeIePgFDAQI" target='_blank' rel='nooppener noreferrer'>{t('learning.connect.137.3')}</a>!</li>
        <li>
          <b>{t('learning.connect.138.1')}</b>: {t('learning.connect.138.2')}
          <div className='text-center'>
            <Image src={connect11} width={'50%'} style={{ objectFit: 'contain' }} />
          </div>
        </li>
        <li><b>{t('learning.connect.139.1')}</b>: {t('learning.connect.139.2')} <a href="https://medium.com/@rikwalters/the-evolution-of-digital-marketing-strategy-in-the-enterprise-1b9687a85e09" target='_blank' rel='nooppener noreferrer'>{t('learning.connect.139.3')}</a>!</li>
      </ol>
      <p>{t('learning.connect.140.1')} <a href="https://makeover.libero.org.rs/external-makeover/" target='_blank' rel='nooppener noreferrer'>{t('learning.connect.140.2')}</a>!</p>
    </>
  )
}

export default ConnectLearningScreen15;